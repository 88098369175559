/*
 * CLASS AND CONTAINER GENERATED BY GOOGLE AUTOCOMPLETE
 *
 * It is used when autocomplete tries to predict a location that the user fills in the input field.
 * 
 * The z-index must be set as high as it is necessary to be correctly shown in the portal
 * and to ensure that the users are able to click on the elements created inside the container.
*/
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 2000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
